/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { updateTotals } from 'Store/Cart/Cart.action';

import { getCustomerData } from '../../data/customer';

const aroundUpdateCartData = async (args) => {
    const [cartData, dispatch] = args;
    const { customerId } = await getCustomerData();

    dispatch(
        {
            ...updateTotals(cartData),
            customerId,
        }
    );
};

export default {
    'Store/Cart/Dispatcher': {
        'member-function': {
            _updateCartData: aroundUpdateCartData,
        },
    },
};
