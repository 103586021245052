/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { prepareQuery } from 'Util/Query';
import { executeGet } from 'Util/Request/Request';

import {
    getNewlyAddedWishlistItemsData,
    getRemovedItemFromWishlist,
    getUpdatedWishlistItems,
} from '../data/wishlist';
import RegularPriceQuery from '../query/RegularPrice.query';
import {
    GTM_EVENT_KEY_ADD_TO_WISHLIST,
    GTM_EVENT_KEY_REMOVE_ALL_ITEMS_FROM_WISHLIST,
    GTM_EVENT_KEY_REMOVE_FROM_WISHLIST,
} from '../util/events';
import { pushToDataLayer } from '../util/push';
import { roundPrice } from '../util/round';
import { debounceCallback } from '../util/wait';

/** @namespace Scandiweb/Gtm/Event/Wishlist/formatAttributesToString */
export const formatAttributesToString = async (attributes) => {
    if (!attributes || attributes.length === 0) {
        return '';
    }

    return attributes
        .map(({ label, value }) => `${label}-${value}`)
        .join('-');
};

/** @namespace Scandiweb/Gtm/Event/Wishlist/fireAddToWishlistEvent */
export const fireAddToWishlistEvent = debounceCallback(
    async (newlyAddedWishlistItemsData = []) => {
    // ^^^ It accepts array for migration in the future
        newlyAddedWishlistItemsData.forEach(async (product) => {
            const isEmptyObject = (obj) => Object.keys(obj).length === 0;

            const {
                quantity, price, discount, currency,
            } = product;
            const { options, id, ...productData } = product;

            const item_variant = (options && !isEmptyObject(options))
                ? await formatAttributesToString(options) : {};

            const query = RegularPriceQuery.getRegularPriceField(id);
            const { getRegularPrice: { price: regularPrice } } = await executeGet(prepareQuery(query));
            const discountApplied = regularPrice - price;

            pushToDataLayer({
                event: GTM_EVENT_KEY_ADD_TO_WISHLIST,
                ecommerce: {
                    currency,
                    value: roundPrice((price * quantity) - discount),
                    items: [{
                        ...productData,
                        discount: roundPrice(discountApplied),
                        ...(!isEmptyObject(item_variant) ? { item_variant } : {}),
                    }],
                },
            });
        });
    }
);

/** @namespace Scandiweb/Gtm/Event/Wishlist/fireRemoveItemFromWishlistEvent */
export const fireRemoveItemFromWishlistEvent = debounceCallback(
    async (newlyRemovedWishlistItemsData = []) => {
    // ^^^ It accepts array for migration in the future
        newlyRemovedWishlistItemsData.forEach(async (product) => {
            const isEmptyObject = (obj) => Object.keys(obj).length === 0;
            const item_variant = (product?.options && !isEmptyObject(product.options))
                ? await formatAttributesToString(product.options) : {};

            const {
                quantity, price, discount, currency,
            } = product;
            const { options, id, ...productData } = product;

            const query = RegularPriceQuery.getRegularPriceField(id);
            const { getRegularPrice: { price: regularPrice } } = await executeGet(prepareQuery(query));
            const discountApplied = regularPrice - price;

            pushToDataLayer({
                event: GTM_EVENT_KEY_REMOVE_FROM_WISHLIST,
                ecommerce: {
                    currency,
                    value: roundPrice((price * quantity) - discount),
                    items: [{
                        ...productData,
                        discount: roundPrice(discountApplied),
                        ...(!isEmptyObject(item_variant) ? { item_variant } : {}),
                    }],
                },
            });
        });
    }
);

/** @namespace Scandiweb/Gtm/Event/Wishlist/fireRemoveAllWishlistItemsEvent */
export const fireRemoveAllWishlistItemsEvent = debounceCallback(async (customerId) => {
    // ! You can't use getStore() because we're in a reducer
    // vvv I'm using == because value can be 0 too but it's acceptable we only need undefined (backup plan) and null
    // vvv and I'm checking for null because in this case it's same as using !isSignedIn()
    if (customerId == null) {
        return;
    }

    pushToDataLayer({
        event: GTM_EVENT_KEY_REMOVE_ALL_ITEMS_FROM_WISHLIST,
    });
});

/** @namespace Scandiweb/Gtm/Event/Wishlist/addToWishlistEventHandler */
export const addToWishlistEventHandler = async (items = []) => {
    if (items.length < 1) {
        return;
    }

    const newlyAddedWishlistItemsData = await getNewlyAddedWishlistItemsData(items);

    fireAddToWishlistEvent(newlyAddedWishlistItemsData);
};

/** @namespace Scandiweb/Gtm/Event/Wishlist/removeItemFromWishlistEventHandler */
export const removeItemFromWishlistEventHandler = async (itemId, productsInWishlist) => {
    // ! You can't use getStore() because we're in a reducer
    const removedItem = await getRemovedItemFromWishlist(itemId, productsInWishlist);

    if (!removedItem.item_id) {
        return;
    }
    // ^^^ if the item is invalid or we didn't find it don't push the data

    fireRemoveItemFromWishlistEvent([removedItem]);
};

/** @namespace Scandiweb/Gtm/Event/Wishlist/wishlistUpdateHandler */
export const wishlistUpdateHandler = debounceCallback(async (newProductsInWishlist, productsInWishlist) => {
    // ! You can't use getStore() because we're in a reducer
    const updatedItems = await getUpdatedWishlistItems(newProductsInWishlist, productsInWishlist);

    if (!updatedItems.length) {
        return;
    }

    updatedItems.forEach((item) => {
        // vvv positive mean we clicked on '+' button
        if (item.quantity > 0) {
            fireAddToWishlistEvent([item]);
        }

        // vvv negative mean we clicked on '-' button
        if (item.quantity < 0) {
            fireRemoveItemFromWishlistEvent([{ ...item, quantity: item.quantity * -1 }]);
            // ^^^ We need a positive number for quantity
        }
    });
});
