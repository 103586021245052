/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

/** @namespace Scandiweb/Gtm/Util/Round/roundPrice */
export const roundPrice = (price) => {
    if (!price) {
        return 0;
    }

    const numericPrice = typeof price === 'string' ? parseFloat(price) : price;

    return parseFloat(numericPrice.toFixed(2));
};
