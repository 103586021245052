/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { getCustomerData } from '../../data/customer';
import {
    fireUserLoginAttemptEvent,
    fireUserLoginEvent,
    fireUserLogoutEvent,
    fireUserRegisterAttemptEvent,
    fireUserRegisterEvent,
} from '../../event/user';

const addFireUserRegisterEvents = async (args, callback) => {
    const [options] = args;

    fireUserRegisterAttemptEvent(options.customer.email);
    await callback(...args);
    // ^^^ this function calls sign in and register
    fireUserRegisterEvent();
};

const addFireUserLoginEvents = async (args, callback) => {
    const [{ email }] = args;

    fireUserLoginAttemptEvent(email);
    await callback(...args);
    fireUserLoginEvent();
};

const addFireUserLogoutEvent = async (args, callback) => {
    const { customerId } = await getCustomerData();

    callback(...args);
    fireUserLogoutEvent(customerId);
};

export default {
    'Store/MyAccount/Dispatcher': {
        'member-function': {
            createAccount: addFireUserRegisterEvents,
            signIn: addFireUserLoginEvents,
            logout: addFireUserLogoutEvent,
        },
    },
};
