const _getOrderField = (args, callback) => callback(args)
    .addFieldList([
        'mollie_redirect_url',
        'mollie_payment_token'
    ]);

export default {
    'Query/Checkout/Query': {
        'member-function': {
            _getOrderField
        }
    }
};
